/* .pagination
{
    margin-top: 10px;
} */
.pagination span{
    font-size: 13px;
}
.pagination button{
    border: 0;
    background-color: transparent;
    cursor: pointer;
}
.pagination select
{
    margin-left: 4px;
    margin-right: 14px;
}
.disablePagBtn
{
    pointer-events: none;
    opacity: 0.45;
}