.user-profile-form {
  background-color: #fff;
  width: 600px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 0px;
}

.form-group {
  display: flex;
  flex-direction: row;
}
.form-group label {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 14px;
  color: #333;
  width: 200px;
  margin-top: 10px;
  margin-left: 10px;
  text-align: right;
  float: left;
}
.form-group select {
  margin-left: 2rem;
  width: 220px;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-rduzuv-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  width: 14rem;
  padding-right: 65px;
  height: 1.5rem;
  margin-left: 1.4rem;
  color: black;
  border: 1px solid #000;

}
.css-rduzuv-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0px 4px 7.5px 6px !important;
  height: 1rem !important;
  margin: -0.5rem;
  font-size: 0.8rem;
}
.form-group input {
  height: 20px;
  width: 220px;
  border: 1px solid #000;
  margin-top: 10px;
  float: left;
  margin-left: 2rem;
}

.user-profile-form {
  max-width: 800px;
  display: grid;
}

.form-group {
  margin-bottom: 20px;
  box-sizing: border-box;
}
.downloadTemplate {
  margin-top: 2rem;
}
.downloadTemplate button {
  background-color: #004c91;
  color: white;
  margin: 0.1rem;
}
.downloadTemplate button:hover {
  background-color: #004c91;
  color: white;
}

.uploadPageBody {
  width: 86wh;
}

.addBody_content {
  width: 86vw;
}
.addBody_content input {
  margin-left: 1rem;
}
.addBody_content button {
  margin-left: 1rem;
}
.AddUserButtons {
  display: flex;
  justify-content: space-evenly;
}
.AddUserContainer {
  text-align: center;
}
.scrollable-container {
  height: 300px;
  overflow-y: auto;
}
.text-center {
  text-align: center;
  width: 100vw;
}
