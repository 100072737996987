.conflicts_tabs {
  border-bottom: 1px solid #bababa;
  margin-top: 20px;
  padding-top: 12px;
  margin-bottom: 20px;
}
.conflicts_tabs ul {
  padding-left: 0;
  margin: 0;
  display: inline-block;
}
.conflicts_tabs ul li {
  list-style-type: none;
  display: inline-block;
  font-size: 14px;
  border-right: 0;
  padding: 8px 18px;
  cursor: pointer;
  color: #969696;
}

.activeTab {
  color: #004c91 !important;
  border-bottom: 2px solid #f47421;
}
.tableCardHeader {
  display: flex;
  padding: 0 26px;
}
.tableCardHeader p {
  font-size: 14px;
  font-weight: 600;
  color: #222;
}
.customCardStyleHandler .MuiAccordionSummary-expandIconWrapper {
  order: -1;
}
.conflictsDiv {
  padding-left: 6.2em;
  font-size: 1.3rem;
  background-color: #f7f8fa;
  color: #717982;
  padding-top: 10px;
}

.referred_options_block {
  float: right;
  margin-right: 30px;
}
.referred_options_block_userScreen {
  float: right;
  margin-right: 30px;
  display: flex;
}
.visit_type_block {
  float: left;
}
.visit_type_block_userScreen{
  float: left;
  margin: 9px;
}

.visit_type_change {
  width: 60px;
  height: 30px;
  border: 1px solid #272727;
}

.filter_block {
  display: inline-block;
  margin-left: 15px;
  border: 2px solid #272727;
  padding: 0px 10px 0px 10px;
  cursor: pointer;
}

.filterBtn {
  background-color: #f7f8fa;
}

.filterIcon {
  position: relative;
  top: 2px;
}

.filter_options_container {
  margin-left: 35px;
}

.filter_label {
  color: #272727;
  font-size: 12px;
  position: relative;
  bottom: 3px;
  padding: 5px;
  cursor: pointer;  
}

.filter_referrer_block,
.filter_patient_block,
.filter_status_block {
  display: inline;
  margin-right: 15px;
}

.filter_status_block {
  position: relative;
  top: 7px;
}

.filter_status_buttons_block {
  display: inline;
  margin-right: 15px;
  position: relative;
  top: 9px;
}

.filter_referrer_label,
.filter_patient_label,
.filter_status_label {
  color: #333333;
  font-size: 13px;
  display: inline-block;
  margin: 20px 10px 0px 0px;
}

.applied_filter_item {
  padding-inline-start: 1rem;
  margin: 0 0.75rem 0.75rem 0;
  font-size: 0.75rem;
  line-height: 1.3333333333;
  min-height: 2.5rem;
  padding: 0 1.5rem;
  font-weight: bold;
  text-align: center;
  display: inline-flex;
  border-radius: 64px;
  cursor: pointer;
  outline: 0;
  vertical-align: top;
  color: #111111;
  background: #f5f5f5;
  justify-content: center;
  align-items: center;
}

.applied_filter_label {
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  font-size: 0.75rem;
  padding-left: 0.5rem;
}

.close_svg_icon {
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
}

em {
  color: #acacac;
}

.add_comment_button {
  border: 1px solid #bababa;
  color: #004c91;
  font-size: 12px;
  height: 35px;
  text-align: center;
  padding-top: 10px;
  width: 60%;
  cursor: pointer;
}

.popup-menu-container {
  position: relative;
}
.popup-menu {
  position: absolute;
  z-index: 2;
  transform: scale(0);
  transform-origin: top left;
  width: 80%;
  background: #fff;
  transition: transform 0.2s;
}
.popup-menu.shown {
  transform: scale(1);
}

.MuiDialog-paperWidthSm {
  width: 35%;
}

.MuiTouchRipple-root {
  display: none !important;
}

.MuiTextField-root {
  margin-top: 0px !important;
}
.downloadButtondiv {
  display: inline;
  margin-right: 15px;
  position: relative;
}
.downloadButtondiv button {
  margin-right: 20px;
  display: flex;
  margin-bottom: 5px;
  font-size: 13.5px;
  padding: 2.5px;
}
.download_button {
  background-color: #004c91 !important;
  color: #FFFFFF !important;
}
.conflicts_tabs_dummy {
  border-bottom: 1px solid #bababa;
  margin-top: 20px;
  padding-top: 12px;
  margin-bottom: 20px;
}
.filter_download {
  display: inline-flex;
}
.filter_download_buttons {
  margin-left: 35px;
  z-index: 100;
  position: absolute;
  background-color: #FFFFFF;
  cursor: pointer;
}
.filter_icon_block {
  display: inline-block;
  margin-left: 15px;
  border: 2px solid #272727;
  padding: 0px 10px 0px 10px;
  cursor: pointer;
}
.filter_icon_block_UserScreen{
  display: inline-block;
  margin-left: 15px;
  border: 2px solid #272727;
  padding: 0px 10px 0px 10px;
  cursor: pointer;
  margin-bottom: 1rem;
}
.filter_list {
  margin-left: 35px;
  z-index: 100;
  position: absolute;
  background-color: white;
  margin: 12px;
  right: -1rem;
}
.filter_list_userScreen {
  margin-left: 35px;
  z-index: 100;
  position: absolute;
  background-color: aliceblue;
  margin: 12px;
  right: 19rem;
  display: flex;
  top: 8rem;
}
.filter_list_userScreen:hover {
  background-color: #eaedf0;
}
.filter_list:hover {
  background-color: #eaedf0;
}
.filter_submit_cancel_buttons {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}
.filter_submit_cancel_buttons button {
  margin-right: 1rem;
}
.filter_options_container_userScreen {
  margin-left: 35px;
  display: flex;
  margin-right: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.filter_submit_cancel_buttonss {
  display: flex;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}
.select_change{
  min-width: 270px;
}